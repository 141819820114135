<template>
  <div>
    <div class="ll-box">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Company') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{ $t('Company list') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- Add Btn -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="info"
          :to="{ name: 'create-customer' }"
          v-permission="[165]"
        >
          <span class="text-nowrap">{{ $t('Create') }}</span>
        </b-button>
      </div>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-b-toggle.filterS
                class="ll-outline"
                :style="filterBg">
                <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="orgName"
                class="searchInput"
                placeholder="Please enter name"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Creation Date from-to')">
            <b-row>
              <b-col>
                <flat-pickr
                  id="formTime"
                  v-model="condition.fromDateTime"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                />
              </b-col>
              <b-col>
                <flat-pickr
                  id="toTime"
                  v-model="condition.toDateTime"
                  class="form-control"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item :title="$t('Is Active')">
            <v-select
              id="isActive"
              :options="statusList"
              label="status"
              placeholder=""
              @input="getStatus"
            ></v-select>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="filterSearch">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[164]"
      :sticky-header="true"
      :busy="isBusy"
      @row-clicked="onDetail"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(isEnabled)="row">
        <b-badge
          :variant="row.value ? 'light-info' : 'light-secondary'"
          class="ll-badge">
          <span>{{ row.value ? 'Active' : 'Inactive' }}</span>
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <b-button
          variant="flat-secondary"
          class="btn-icon rounded-circle ll-act"
          :to="{ name: 'customer-details', params: { id: data.item.id } }"
        >
          <feather-icon icon="EditIcon"/>
        </b-button>
        <!-- <feather-icon
          :id="`${data.item.id}`"
          icon="EditIcon"
          size="16"
          class="mx-1"
          @click="onDetail(data.item.id)"
          v-permission="[166]"
        >
        <b-tooltip
          title="View Details"
          :target="`${data.item.id}`"
        />
        </feather-icon> -->
      </template>
    </b-table>
    <!-- show pages -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPagination, BFormSelect, BButton, BTooltip, VBToggle,
  BCard, BCardBody, BTable, BRow, BCol, BFormInput, BFormGroup, BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem, BBadge, BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BPagination,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: "",
      sortDesc: true,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'orgCode', label: this.$t('Org ID'), sortable: true },
        { key: 'name', label: this.$t('Name'), sortable: true },
        { key: 'displayName', label: this.$t('Company Name'), sortable: true },
        { key: 'orgType', label: this.$t('Company Type'), sortable: true },
        { key: 'subType', label: this.$t('Sub Type'), sortable: true },
        { key: 'country', label: this.$t('Country'), sortable: true },
        { key: 'createdAt', label: this.$t('Creation Time'), sortable: true },
        { key: 'isEnabled', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      orgName: '',
      condition: {
        page: '1',
        size: '50',
        orderBy: 'id',
        orderMole: 1,
        name: null,
        isEnabled: null,
        fromDateTime: null,
        toDateTime: null,
      },
      statusList: [
        { value: true, status: 'active' },
        { value: false, status: 'inactive' },
      ],
      statysType: '',
      statusTypeList: [],
      filterBg: {
        '--background': '#fff'
      },
      isBusy: true,
    }
  },
  computed: {
  },
  watch: {
    'condition.fromDateTime'(val) {
      this.setBg(val)
    },
    'condition.toDateTime'(val) {
      this.setBg(val)
    },
    'condition.isEnabled'(val) {
      this.setBg(val)
    },
  },
  created() {
  },
  mounted() {
    // this.getList()
    this.ability()
    this.ability1()
  },
  methods: {
    // 角色-公司
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      // console.log(arr)
      const name = 'LMD Customer'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.condition.name = userData.organizationName
        this.getList()
      } else {
        this.condition.name = null
        this.getList()
      }
    },
    // 角色-商户
    ability1() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      // console.log(arr)
      const name = 'seller'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        const id = userData.orgId
        // this.$router.push({ name: 'customer-details', params: { id: id} })
      }
    },
    // filter图标背景颜色设置
    setBg(val) {
      if (val !== null && val !== '') {
        this.filterBg= {
          '--background': '#ff9f43'
        }
      } else {
        this.filterBg= {
          '--background': '#fff'
        }
      }
    },
    // 首次查询
    getList() {
      this.$http.post('admin/settings/organization/list', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    onRowClick() {
    },
    onSearch() {
    },
    // 根据SLAID查询
    searchById() {
      this.condition.name = this.orgName.trim()
      this.getList()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      // console.log(orderBy, orderMole)
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    getStatus(s) {
      this.condition.isEnabled = s.value
    },
    // 条件过滤搜索
    filterSearch() {
      this.getList()
    },
    onClear() {
      this.condition.fromDateTime = null
      this.condition.toDateTime = null
      this.condition.isEnabled = null
    },
    onDetail(item){
      this.$router.push({ name: 'customer-details', params: { id: item.id, oid: item.orgCode }})
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: absolute;
  bottom: 30px;
  right: 10px;
} */
.btn-mrg{
  margin-left: 10px;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-outline> div{
  background: var(--background);
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
.ll-collapse .card{
  box-shadow: none;
}
</style>
